
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";
import ETable from "@/components/ETable.vue";

import BatchRestDate from "./components/batchRestDate.vue";
import RestSettingDetail from "./components/batchRestDateDetail.vue";

import { getStoreRestRecords, delRecordStoreRest } from "@/api/booking";

import dayjs from "dayjs";

@Component({
    components: { ETable, BatchRestDate, RestSettingDetail }
})
export default class RestSetting extends Mixins(loading, tablePagination, Resize) {
    queryForm = {
        storeName: "",
    }

    itemTotal = 0;
    tableData = [];

    columns = [
        { label: "休息日期", prop: "restList" },
        { label: "关联门店数", prop: "storeIds" },
        { label: "操作人", prop: "modifiedBy" },
        { label: "最后操作时间", prop: "modifiedTime" },
        { label: "备注", prop: "remark" },
        { label: "操作", prop: "action" },
    ];

    dialogFlag = false;
    detailFlag = false;

    viewRecordId = null;

    mounted() {
        this.windowResize(310)
        this.getData();
    }

    getData() {
        const params = {
            storeName: this.queryForm.storeName,
        };
        getStoreRestRecords(params).then((res) => {
            this.tableData = res.data;
        }).finally(() => {
            this.hideLoading();
        })
    }

    //重置分页参数
    resetPages() {
        this.currentPage = 1;
    }

    // 搜索
    handleSearch() {
        this.resetPages();
        this.getData();
    }

    handleEdit() {
        this.dialogFlag = true;
    }

    showSettingDetail(row) {
        this.detailFlag = true;
        this.viewRecordId = row.id;
    }

    del(row) {
        this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            delRecordStoreRest(row.id).then(() => {
                this.$message.success("删除成功");
                this.getData();
            })
        })
    }

    getRestDateDesc(restDateList) {
        return restDateList?.map((el) => dayjs(el).format("MM-DD"))?.join("、");
    }

}
