
import { Watch, PropSync, Component, Vue, Mixins, Prop } from "vue-property-decorator";
import ETable from "@/components/ETable.vue";

import tablePagination from "@/mixins/tablePagination";

import { getRestRecordStoreList } from "@/api/booking";

@Component({
    components: { ETable }
})
export default class RestSettingDetail extends Mixins(tablePagination) {
[x: string]: any;
  @PropSync("formVisible") visible: boolean;
  @Prop() id: string | number;

  loading = false;

  columns = [
    { label: "门店类型", prop: "storeType" },
    { label: "门店名称", prop: "storeName" },
  ];
  tableData = [];

  @Watch("id")
  getData() {
    if (!this.id) return;
    this.loading = true;
    getRestRecordStoreList(this.id).then((res) => {
      this.tableData = res.data;
    }).finally(() => {
      this.loading = false;
    })
  }

}
