
interface IStoreActivityForm {
  [key: string]: any;
}

import {
  Watch,
  Inject,
  Component,
  Vue,
  PropSync,
  Prop,
  Ref,
  InjectReactive,
  Emit,
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";

import { Form, Transfer } from "element-ui";

import { cloneDeep } from "lodash";
import dayjs from "dayjs";

import { listBookStoreByType, addStoreRestRecord } from "@/api/booking";

@Component({
  components: {
    UploadImg,
  },
})
export default class BatchRestDate extends Vue {
  @PropSync("formVisible") visible: boolean;
  @Ref("formRef") formRef!: Form;
  @Ref("transferRef") transferRef: Transfer;

  restDateListPickerOptions = {
    disabledDate(v) {
      return v.getTime() < new Date().getTime() - 86400000;
    }
  }

  saveLoading = false;

  storeKeyValueMap = {};
  formData = {
    restDateList: [],
    storeList: [],
    remark: "",
    storeListDesc: "",
  }

  formRules = {
    restDateList: { required: true, type: "array", message: "请选择休息日期" },
    storeList: { required: true, type: "array", message: "请选择关联门店" },
  };

  storeList = [];
  stashStoreList = [];

  showTransfer = false;

  mounted() {
    this.getStoreList();
  }

  getStoreList() {
    listBookStoreByType().then((res) => {
        this.storeList = res.data;
        this.setStoreKeyValueMap(this.storeList);
    })
  }

  restDateListListChange($event) {
    const _list = this.formData.restDateList?.sort((el1, el2) => {
      return el1?.getTime() - el2?.getTime()
    })
    this.formData.restDateList = cloneDeep(_list)
  }

  handlerestDateListClose(cur,index) {
    this.formData.restDateList?.splice(index, 1)
  }

  getTimeString(time) {
    return dayjs(time)?.format("MM月DD日")
  }

    @Watch("formData.storeList", { immediate: true, deep: true })
    setStoreListDesc(value) {
        if (!value) return;
        this.formData.storeListDesc = value?.map((el) => {
        return this.storeKeyValueMap[el]
        })?.join("/")
    }
  setStoreKeyValueMap(value) {
    if (!value) return;
    this.storeKeyValueMap = {};
    value?.forEach((el) => {
      this.storeKeyValueMap[el.id] = el.storeName;
    })
  }
  addStore() {
    this.showTransfer = true;
    // TODO: 将form.storeList 回显
    this.stashStoreList = this.formData.storeList;
    this.transferRef?.clearQuery("left");
    this.transferRef?.clearQuery("right");
  }

  closeTransfer() {
    this.showTransfer = false;
    this.stashStoreList = [];
  }

  confirmTransfer() {
    this.formData.storeList = [...this.stashStoreList];
    this.closeTransfer();
  }

  handleClose(): void {
    this.cancel();
  }

  confirm() {
    this.formRef.validate().then(() => {
      const { restDateList, storeList, remark } = this.formData;
      const params = {
        restDateList: restDateList?.map(el => dayjs(el).format("YYYY-MM-DD HH:mm:ss")),
        storeIds: storeList,
        remark
      }
      this.saveLoading = true;
      addStoreRestRecord(params).then((res) => {
        this.$message.success("操作成功");
        this.syncList();
        this.handleClose();
      }).finally(() => {
        this.saveLoading = false;
      })
    })
  }

  @Emit("syncList")
  syncList() {
    return true;
  }

  cancel() {
    this.formRef?.resetFields();
    this.visible = false;
  }
}
